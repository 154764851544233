import { Box, Dialog, Grid, Slide, Typography, useTheme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect } from "react";
import MainLogo from "../../../../assets/logo-white.png";
import NotifySong from "../../../../assets/notify.wav";
import PanelBg from "../../../../assets/panel-bg.png";
import { IServicePassword } from "../../../../models";
import { Image } from "../../styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ICallModalProps {
  currentServicePassword: IServicePassword | null;
  setCurrentServicePassword: (
    currentServicePassword: IServicePassword | null
  ) => void;
}

const CallModal = ({
  currentServicePassword,
  setCurrentServicePassword,
}: ICallModalProps) => {
  const theme = useTheme();

  useEffect(() => {
    const audio = new Audio(NotifySong);
    audio
      .play()
      .then(() => {
        console.log("Sound play", currentServicePassword);
      })
      .catch(() => {
        console.log("Sound not play", currentServicePassword);
      });
  }, [currentServicePassword]);

  return (
    <Dialog
      fullScreen
      open={currentServicePassword !== null}
      TransitionComponent={Transition}
      onClose={() => setCurrentServicePassword(null)}
    >
      <Box
        sx={{
          background: `url(${PanelBg}), ${theme.palette.primary.light} center center/cover`,
          height: "100vh",
          width: "100%",
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Grid container>
          <Grid item lg={12} display="flex" justifyContent="center" mt={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "8rem",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
              }}
            >
              ATENÇÃO
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={4} spacing={2}>
          <Grid
            item
            lg={6}
            display="flex"
            alignItems="center"
            flexDirection="column"
            sx={{
              borderRight: "4px solid #fff",
            }}
          >
            <Typography
              component="h1"
              sx={{
                fontSize: "5rem",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
              }}
            >
              SENHA
            </Typography>
            <Typography
              component="h1"
              sx={{
                fontSize: "6rem",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
              }}
            >
              {currentServicePassword?.senha}
            </Typography>
            <Typography
              alignItems="center"
              component="h1"
              sx={{
                fontSize: "2.2rem",
                fontWeight: 600,
                color: '#fff',
                textTransform: "uppercase",
                marginLeft: '15px',
                textAlign: "center"
              }}
            >
              {currentServicePassword?.idgrupoatendimento?.nome} - {currentServicePassword?.tiposervico?.nome} 
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              component="h1"
              sx={{
                fontSize: "5rem",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
              }}
            >
              GUICHÊ
            </Typography>
            <Typography
              component="h1"
              sx={{
                fontSize: "6rem",
                fontWeight: 600,
                color: theme.palette.background.default,
                textTransform: "uppercase",
              }}
            >
              {currentServicePassword?.guiche?.nome}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={8}
        >
          <Grid item lg={2} md={12} sm={12}>
            <Image src={MainLogo} alt="Logo" />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CallModal;
