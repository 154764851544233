import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";

import PanelBg from "../../assets/panel-bg.png";
import { api, socket } from "../../services/api";

import { usePanel } from "../../contexts/PanelContext";
import {
  ICallPanel,
  ICallPanelOffice,
  ICallPanelsOffice,
  PatientOffice,
} from "../../models";
import CallPatientModal from "./components/CallPatientModal";
import Footer from "./components/Footer";
import Header from "./components/Header";

const TIME_LIMIT = 6;
const TIME_INITIAL = 1;

function OfficePanel() {
  const theme = useTheme();
  const [historyPatients, setHistoryPatients] = useState<PatientOffice[]>([]);
  const { panel, isOpen } = usePanel();
  const [currentPatient, setCurrentPatient] = useState<string>("");
  const [currentProfessional, setCurrentProfessional] = useState<string>("");
  const [currentPrioritario, setCurrentPrioritario] = useState<boolean>(false);
  const [currentPanelOffice, setCurrentPanelOffice] =
    useState<ICallPanelOffice | null>(null);
  const [panelsOffice, setPanelsOffice] = useState<ICallPanelsOffice[]>([]);

  const [clock, setClock] = useState<number>(TIME_INITIAL);
  const [currentIndex, setCurrentIndex] = useState(0);

  const msg = useMemo(() => new SpeechSynthesisUtterance(), []);

  useEffect(() => {
    if (currentPanelOffice !== null) {
      msg.text = "";
      // msg.text = `Atenção! Paciente ${currentPatient}!`;
      msg.text = `Atenção! Nova chamada!`;
      msg.rate = 0.8;
      window.speechSynthesis.speak(msg);
    }
  }, [currentPanelOffice, currentPatient, msg]);

  const getAllOfficePatients = async (panel: ICallPanel) => {
    try {
      const res = await api.get(
        `/users-patients/patient-office-bypanelid?idpainelchamado=${panel?.idpainelchamado}`
      );

      setHistoryPatients(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (panel !== null && isOpen) {
      getAllOfficePatients(panel);
    }
  }, [panel, panelsOffice, isOpen]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("[IO] => connected");
    });
    socket.on(`panelOffice`, async (data: any) => {
      setPanelsOffice((old) => [...old, data]);
    });
  }, []);

  useEffect(() => {
    let time: any = null;
    if (clock < TIME_LIMIT && currentPanelOffice !== null) {
      time = setInterval(() => {
        setClock((state) => state + 1);
      }, 1000);
    } else {
      if (panelsOffice.length - 1 < currentIndex) {
        setCurrentIndex(0);
        setPanelsOffice([]);
      } else {
        setCurrentIndex((old) => old + 1);
      }
      setClock(TIME_INITIAL);
    }
    return () => clearInterval(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock, currentPanelOffice, panel]);

  useEffect(() => {
    if (
      panelsOffice.length > 0 &&
      currentIndex <= panelsOffice.length - 1 &&
      isOpen
    ) {
      setCurrentPanelOffice(
        panelsOffice[currentIndex].panels.find(
          (item) => item.idpainelchamado === panel?.idpainelchamado
        ) || null
      );
      setCurrentPatient(panelsOffice[currentIndex].nomepaciente);
      setCurrentProfessional(panelsOffice[currentIndex].nomeprofissional || "");
      setCurrentPrioritario(panelsOffice[currentIndex].prioritario || false);
    } else {
      setCurrentPanelOffice(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelsOffice, currentIndex, panel, isOpen]);

  return (
    <>
      {currentPanelOffice !== null && isOpen && (
        <CallPatientModal
          currentPanelOffice={currentPanelOffice}
          currentPatient={currentPatient}
          currentPrioritario={currentPrioritario}
          currentProfessional={currentProfessional}
        />
      )}
      <Box
        sx={{
          background: `url(${PanelBg}), ${theme.palette.primary.light} center center/cover`,
          height: "100vh",
          width: "100%",
        }}
      >
        <Header />

        {!isOpen ? (
          <Grid
            container
            sx={{
              height: "80vh",
              pl: 2,
            }}
          >
            <Grid item lg={12} xs={12} display="flex" justifyContent="center">
              <Typography
                component="h1"
                sx={{
                  fontSize: "40px",
                  fontWeight: 600,
                  color: theme.palette.secondary.light,
                  textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                }}
              >
                ATENÇÃO! PAINEL FECHADO
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={{
              height: "80vh",
              pl: 2,
            }}
          >
            <Grid item lg={6} xs={12} pt={4}>
              {currentPanelOffice !== null ? (
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "40px",
                        fontWeight: 600,
                        color: theme.palette.secondary.light,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      ATENÇÃO!
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    mt={1}
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "40px",
                        fontWeight: 500,
                        color: theme.palette.background.default,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Paciente:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "50px",
                        fontWeight: 600,
                        color: theme.palette.background.default,
                        textTransform: "uppercase",
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                        textAlign: "center",
                      }}
                    >
                      {currentPatient}
                    </Typography>
                  </Grid>
                  {currentProfessional !== "" && (
                    <>
                      <Grid
                        item
                        lg={6}
                        mt={4}
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <Typography
                          component="h1"
                          sx={{
                            fontSize: "30px",
                            fontWeight: 500,
                            color: theme.palette.background.default,
                            textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          Profissional -:
                        </Typography>
                        <Typography
                          component="h1"
                          sx={{
                            fontSize: "42px",
                            fontWeight: 600,
                            color: theme.palette.background.default,
                            textTransform: "uppercase",
                            textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {currentProfessional}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    lg={6}
                    mt={4}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "30px",
                        fontWeight: 500,
                        color: theme.palette.background.default,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Consultório:
                    </Typography>
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "50px",
                        fontWeight: 600,
                        color: theme.palette.background.default,
                        textTransform: "uppercase",
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {currentPanelOffice.consultorio.nome}
                    </Typography>
                  </Grid>

                  {/* <Grid
                  item
                  lg={12}
                  xs={12}
                  mt={2}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "30px",
                      fontWeight: 500,
                      color: theme.palette.background.default,
                      textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    Prioridade:
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "32px",
                      fontWeight: 600,
                      color: theme.palette.background.default,
                      textTransform: "uppercase",
                      textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    {currentPrioritario ? "SIM" : "NÃO"}
                  </Typography>
                </Grid> */}
                </Grid>
              ) : historyPatients.length > 0 ? (
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "40px",
                        fontWeight: 600,
                        color: theme.palette.secondary.light,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      ATENÇÃO!
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    mt={1}
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "30px",
                        fontWeight: 500,
                        color: theme.palette.background.default,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Paciente:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                        color: theme.palette.background.default,
                        textTransform: "uppercase",
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                        textAlign: "center",
                      }}
                    >
                      {historyPatients[0].paciente.nomesocial &&
                      historyPatients[0].paciente.nomepaciente.length > 0
                        ? historyPatients[0].paciente.nomesocial
                        : historyPatients[0].paciente.nomepaciente}
                    </Typography>
                  </Grid>
                  {historyPatients[0].profissional && (
                    <>
                      <Grid
                        item
                        lg={12}
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        mt={4}
                        alignItems="center"
                        flexDirection="column"
                      >
                        <Typography
                          component="h1"
                          sx={{
                            fontSize: "30px",
                            fontWeight: 500,
                            color: theme.palette.background.default,
                            textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          Profissional:
                        </Typography>
                        <Typography
                          component="h1"
                          sx={{
                            fontSize: "36px",
                            fontWeight: 600,
                            color: theme.palette.background.default,
                            textTransform: "uppercase",
                            textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {historyPatients[0].profissional.nomecomercial
                            ? historyPatients[0].profissional.nomecomercial
                            : historyPatients[0].profissional.nome}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    lg={12}
                    mt={4}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "30px",
                        fontWeight: 500,
                        color: theme.palette.background.default,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Consultório:
                    </Typography>
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "40px",
                        fontWeight: 600,
                        color: theme.palette.background.default,
                        textTransform: "uppercase",
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {historyPatients[0].consultorio.nome}
                    </Typography>
                  </Grid>

                  {historyPatients[0].prioritario !== undefined && (
                    <>
                      {/* <Grid
                      item
                      lg={12}
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      mt={2}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "30px",
                          fontWeight: 500,
                          color: theme.palette.background.default,
                          textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        Prioridade:
                      </Typography>
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "32px",
                          fontWeight: 600,
                          color: theme.palette.background.default,
                          textTransform: "uppercase",
                          textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {historyPatients[0].prioritario ? "SIM" : "NÃO"}
                      </Typography>
                    </Grid> */}
                    </>
                  )}
                </Grid>
              ) : (
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "40px",
                        fontWeight: 600,
                        color: theme.palette.secondary.light,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      ATENÇÃO!
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    mt={1}
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "40px",
                        fontWeight: 500,
                        color: theme.palette.background.default,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Aguarde você ser chamado
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item lg={6} xs={12} display="flex" alignItems="center">
              <Grid
                container
                sx={{
                  backgroundColor: theme.palette.background.default,
                  overflowY: "scroll",
                  maxHeight: "80vh",
                  height: "100%",
                }}
              >
                <Grid item lg={12} xs={12} md={12}>
                  <Grid container maxHeight="100%">
                    <Grid
                      item
                      lg={6}
                      xs={6}
                      md={6}
                      sx={{
                        backgroundColor: theme.palette.primary.dark,
                        p: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "24px",
                          color: theme.palette.background.default,
                          textTransform: "uppercase",
                        }}
                      >
                        NOME
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      xs={6}
                      md={6}
                      sx={{
                        backgroundColor: theme.palette.primary.dark,
                        p: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "24px",
                          color: theme.palette.background.default,
                          textTransform: "uppercase",
                        }}
                      >
                        CONSULTÓRIO
                      </Typography>
                    </Grid>
                    {historyPatients.length === 0 ? (
                      <Grid
                        item
                        lg={12}
                        xs={12}
                        sx={{
                          p: 2,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Typography
                          component="h1"
                          sx={{
                            fontSize: "28px",
                            fontWeight: 600,
                            color: theme.palette.primary.dark,
                            textTransform: "uppercase",
                          }}
                        >
                          Histórico de chamadas
                        </Typography>
                      </Grid>
                    ) : (
                      historyPatients.map((item) => (
                        <Fragment key={item.idpacienteconsultorio}>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            sx={{
                              p: 1,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              component="h1"
                              sx={{
                                fontSize: "25px",
                                fontWeight: 800,
                                color: theme.palette.primary.dark,
                                textTransform: "uppercase",
                              }}
                            >
                              {item.paciente.nomesocial &&
                              item.paciente.nomesocial.length > 0
                                ? item.paciente.nomesocial
                                : item.paciente.nomepaciente}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            sx={{
                              p: 1,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              component="h1"
                              sx={{
                                fontSize:
                                  item.consultorio &&
                                  item.consultorio.nome.length < 4
                                    ? "42px"
                                    : "28px",
                                fontWeight: 600,
                                color: theme.palette.primary.dark,
                                textTransform: "uppercase",
                              }}
                            >
                              {item.consultorio
                                ? item.consultorio.nome
                                : "Aguardando"}
                            </Typography>
                          </Grid>
                        </Fragment>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Footer />
      </Box>
    </>
  );
}

export default OfficePanel;
